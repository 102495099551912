export enum Roles {
  Staff = "staff",
  Merchant = "merchant",
  Freelancer = "freelancer",
}

export enum BusinessCategory {
  Contractor = "contractor",
  Beauty = "beauty",
  Comestic = "comestic",
  HealthWellbeing = "health-wellbeing",
  Fitness = "fitness",
  VehicleCare = "vehicle-care",
  HouseCare = "house-care",
  Food = "food",
  Technology = "technology",
  Misc = "misc",
  Trades = "trades",
}

export interface NavProps {
  name: string;
  path: string;
  icon: string;
  category: BusinessCategory[];
  gap?: boolean;
  badge?: boolean;
  class?: string;
  roles?: Roles[];
}

const NavigationRoutes: NavProps[] = [
  {
    name: "home",
    path: "/dashboard/home",
    icon: "akar-icons:home",
    roles: [...Object.values(Roles)],
    category: [...Object.values(BusinessCategory)],
  },
  {
    name: "services",
    path: "/dashboard/service",
    icon: "ic:outline-local-offer",
    class: "first-step",
    roles: [...Object.values(Roles).filter((role) => role !== Roles.Staff)],
    category: [...Object.values(BusinessCategory)],
  },
  {
    name: "Employee Availability",
    path: "/dashboard/schedule",
    icon: "akar-icons:schedule",
    class: "second-step",
    roles: [...Object.values(Roles).filter((role) => role !== Roles.Staff)],
    category: [
      ...Object.values(BusinessCategory).filter(
        (category) => category !== BusinessCategory.Contractor
      ),
    ],
  },
  {
    name: "staff",
    path: "/dashboard/staff",
    icon: "bi:person-badge",
    gap: true,
    class: "third-step",
    roles: [
      ...Object.values(Roles).filter((role) => role !== Roles.Freelancer),
    ],
    category: [
      ...Object.values(BusinessCategory).filter(
        (category) => category !== BusinessCategory.Contractor
      ),
    ],
  },
  {
    name: "CRM",
    path: "/dashboard/crm",
    icon: "ri:customer-service-2-line",
    gap: false,
    class: "third-step",
    roles: [...Object.values(Roles).filter((role) => role !== Roles.Staff)],
    category: [
      ...Object.values(BusinessCategory).filter(
        (category) => category !== BusinessCategory.Contractor
      ),
    ],
  },
  {
    name: "Loyalty feature",
    path: "/dashboard/loyalty-program",
    icon: "ri:customer-service-2-line",
    gap: false,
    class: "third-step",
    category: [
      ...Object.values(BusinessCategory).filter(
        (category) => category !== BusinessCategory.Contractor
      ),
    ],
  },
  {
    name: "Service Quote",
    path: "/dashboard/service-quote",
    icon: "dashicons:pdf",
    gap: false,
    class: "third-step",
    category: [
      ...Object.values(BusinessCategory).filter(
        (category) => category !== BusinessCategory.Contractor
      ),
    ],
  },
  {
    name: "reviews",
    path: "/dashboard/reviews",
    icon: "akar-icons:star",
    category: [
      ...Object.values(BusinessCategory).filter(
        (category) => category !== BusinessCategory.Contractor
      ),
    ],
  },
  {
    name: "appointments",
    path: "/dashboard/appointments",
    icon: "carbon:task-asset-view",
    badge: true,
    class: "fourth-step",
    category: [...Object.values(BusinessCategory)],
  },
  {
    name: "analytics",
    path: "/dashboard/analytics",
    icon: "ion:analytics-outline",
    gap: true,
    category: [
      ...Object.values(BusinessCategory).filter(
        (category) => category !== BusinessCategory.Contractor
      ),
    ],
  },
  {
    name: "gallery",
    path: "/dashboard/gallery",
    icon: "clarity:image-gallery-line",
    category: [
      ...Object.values(BusinessCategory).filter(
        (category) => category !== BusinessCategory.Contractor
      ),
    ],
  },
  {
    name: "Your FAQs",
    path: "/dashboard/faq",
    icon: "akar-icons:chat-question",
    category: [
      ...Object.values(BusinessCategory).filter(
        (category) => category !== BusinessCategory.Contractor
      ),
    ],
  },
  {
    name: "business info",
    path: "/dashboard/businessinfo",
    icon: "clarity:store-line",
    class: "fifth-step",
    category: [...Object.values(BusinessCategory)],
  },
];

export const SubscriptionRoutes = ["CRM"];

export default NavigationRoutes;
